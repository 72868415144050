
.headerLink {
    color: var(--light);
    text-decoration: none;
    font-weight: bold;
    transition: color 0.1s ease-in-out 0.1s;
}

.headerLink:hover {
    color: var(--lightest);
}

.footer {
    min-width: 100%;
    max-width: 100%;
    text-align: center;
    min-height: 10vh;
    max-height: 10vh;
    background-color: var(--dark);
    /* padding: 50px 0px; */
    height: 10vh;

    /* border-top: 3px solid white; */
    transform: translateZ(0);
    color: var(--text);
    display:flex;
    justify-content: space-evenly;
    /* border-top: 3px solid var(--dark); */
}


.footerIcons {
    font-size: xx-large;
    margin: 2vh 5px 0 5px;
    text-decoration: none;
}