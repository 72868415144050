.activeBtn {
  background-color: var(--darkest) !important;
}

.SubredditButtonContainer {
  margin: 0 5px;
}
.minifiedButtonContainer{
  margin: 0px;
}
button{
  border: none;

}

.SubredditButton {
  color: var(--text);
  background-color: var(--dark);
  /* Btn css */
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0px 20px;
  width: 100%;
  border-radius: 40px;
  /* height: 40px; */
  max-width: 250px;
  margin: 1rem auto;
  text-transform: uppercase;
  /* border: 1px solid currentColor; */

  /* below is for text / anhor tag if used */
  text-decoration: none;
  line-height: 40px;
}
.minifiedButton{
  color: var(--text);
  background-color: var(--dark);
  width: 100%;
  position: relative;
  border-radius: 40px;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 20px;
  padding: 0px 2px;
}

.SubredditButton:hover {
  /* color: #f1f1f1; */
  /* background-color: #a125a1; */
  cursor: pointer;
  color: var(--lightest);
  transition: background-color 0.4s ease-in-out 0.1s;
}

@media (hover: hover) and (pointer: fine) {
  .SubredditButtonSpan {
    position: absolute;
    /* display: block; */
    display: none;
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgb(2, 0, 36);
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 0.5) 0%,
      rgba(9, 9, 121, 0.5) 35%,
      rgba(0, 212, 255, 0.5) 100%
    );
    transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0.5;
  }
  F .SubredditButtonSpan:hover {
    width: 225%;
    height: 10%;
  }
}

.SubredditButton:active {
  /* background-color: #54125a; */
}
