
  
  input[type=checkbox] {
    display: none;
  }
  
  .circle {
    position: relative;
    width: 100px;
    height: 100px;
    top: 100px;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: url('#gooey');
  }
  
  @keyframes circle__in {
    0% {
      transform: translate(-50%, -50%) scale(1);
    }
  
    16% {
      transform: translate(-50%, -50%) scale(0.95, 1.05);
    }
  
    33% {
      transform: translate(-50%, -50%) scale(1);
    }
  
    50% {
      transform: translate(-50%, -50%) scale(1.05, 0.95);
    }
  
    66% {
      transform: translate(-50%, -50%) scale(1);
    }
  
    83% {
      transform: translate(-50%, -50%) scale(0.98, 1.02);
    }
  
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  
  
  input:checked + .circle {
    transform-origin: 50% 50%;
    animation-name: circle__in;
    animation-duration: 750ms;
    animation-timing-function: linear;
  }
  
  .circle {
    transform-origin: 50% 50%;
    animation-name: circle__out;
    animation-duration: 1000ms;
    animation-timing-function: ease;
  }
  
  .circle--outer {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 6px solid white;
  }
  
  .circle--inner {
    top: 15px;
    left: 15px;
    position: absolute;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    background: white;
  }
  
  @keyframes circle--inner__in {
    0% {
      transform: scale(0.0);
    }
  
    80% {
      transform: scale(1.02);
    }
  
    100% {
      transform: scale(1);
    }
  }
  
  input:checked + .circle .circle--inner {
    transform-origin: 50% -20%;
    animation-name: circle--inner__in;
    animation-duration: 500ms;
    animation-timing-function: cubic-bezier(0.85, 0, 0.2, 1);
  }
  
  @keyframes circle--inner__out {
    0% {
      transform: scale(1);
    }
  
    80% {
      transform: scale(0.19);
    }
  
    99% {
      transform: scale(0.21);
    }
  
    100% {
      transform: scale(0);
    }
  }
  
  .circle--inner {
    animation-name: circle--inner__out;
    animation-duration: 500ms;
    animation-timing-function: cubic-bezier(0.85, 0, 0.2, 1);
    animation-fill-mode: forwards;
  }
  
  .circle--inner__1 { transform-origin: -12% -8%; }
  .circle--inner__2 { transform-origin: -35% 50%; }
  .circle--inner__3 { transform-origin: 60% 130%; }
  .circle--inner__4 { transform-origin: 112% 90%; }
  .circle--inner__5 { transform-origin: 75% -30%; }
  
  // dribbble - twitter
  .dribbble {
    position: fixed;
    display: block;
    right: 20px;
    bottom: 20px;
    img {
      display: block;
      height: 28px;
    }
  }
  .twitter {
    position: fixed;
    display: block;
    right: 64px;
    bottom: 14px;
    svg {
      width: 32px;
      height: 32px;
      fill: #1da1f2;
    }
  }
  