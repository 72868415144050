.portfolioContainer {
  // display: flex;

  // flex-grow: 1;
  // justify-content: center;
  // align-items: center;

  /* Set a specific height */
  // min-height: 80vh;
  height: 100%;
  min-height:80vh;
  max-height: 100%;
  
  border-radius: 100px;
  
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.sectionTitle{
  color: var(--text);
  text-align: center;
  margin-top: 0px;
}
/* .backgroundContainer::before {
    content: "";
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(#f00, #f0f);
    clip-path: circle(30% at right 70%);
  }
  .backgroundContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(#2196f3, #e91e63);
    clip-path: circle(20% at 10% 10%);
  } */
.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  left: 10%;
  flex-wrap: wrap;
  z-index: 1;
  /* border: 2px dash orange; */
}

.container .card {
  position: relative;
  width: 280px;
  height: 400px;
  margin: 30px;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  transform-style: preserve-3d;
  perspective: 500px;
}

.endCard {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 30px;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  transform-style: preserve-3d;
}

.container .card .content {
  padding: 20px;
  text-align: center;
  transform: translateY(0);
  opacity: 1;
  transition: 0.5s;
  transition: transform 0.25;
  
}

.endContent {
  font-size: 1.8em;
  color: var(--text);
  z-index: 1;
  opacity: 0.7;
  font-weight: 500;
}

.container .card h2 {
  position: absolute;
  top: 0px;
  right: 30px;
  font-size: 8em;
  color: var(--invertedText);
  pointer-events: none;
  opacity: 0.7;
}
.container .card .content h3 {
  font-size: 1.8em;
  color: var(--text);
  z-index: 1;
  opacity: 0.7;
}

.container .card .content p {
  font-size: 1em;
  color: var(--text);
  font-weight: 300;
  padding-bottom: 10px;
}

.container .card .content a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: var(--invertedText);
  margin-top: 15px;
  border-radius: 20px;
  text-decoration: none;
  color: var(--text);
  font-weight: 500;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  opacity: 0.5;
}
.container .card .content a:hover {
  opacity: 0.8;
  transform: scale(1.1) translateZ(50px);
  transition: 0.5s;
}
.tags {
  display: inline;
  padding: 1px 5px;
  border-radius: 5px;
  color: white;
  font-size: initial;
  font-weight: normal;
}
.iconContainer {
  // height: 200%;
  z-index: 1;
  font-size: 100px;
  // text-align: left;
}
.card:hover .content .iconContainer {
  transform: scale(1.75) translateZ(50px) translateY(-20px);
  transition: 0.5s;
}

