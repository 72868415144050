/* Parent Container of  BubbleTile */
.parent {
  /* perspective: 1px; */
  /* min-height: 100vh; */
  overflow-x: hidden;
  overflow-y: visible;
  /* background-color: var(--dark); */

  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
  background-attachment: scroll;
  position: relative;
  
}

/* Parent contianer of the BubbleTileController page, used to give a unique class ID */
.divisionsContainer {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    #dccdf8,
    #d8cef9,
    #d3d0fb,
    #cfd1fb,
    #cbd2fc,
    #c0d6ff,
    #b5d9ff,
    #abddff,
    #95e5ff,
    #7dedff,
    #68f5fe,
    #5ffbf1
  );
  padding-top: 10vh;
}

/*-------- Bubble CSS --------*/
/* Container for all bubbles */
.bubbleContainer {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  margin-top: 1px;
  width: 100%;
  height: 100px;
}
.bubbleContainerNoHeight {
  height: 0;
}

/* Each unqiue bubble */
.Bubbles {
  position: absolute;
  box-shadow: 0 0 5px var(--shadowStart), 4px 4px 5px var(--shadowEnd);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dark);
  scroll-behavior: smooth;
}

/* Bubble size increase on hover, only on vertical alignment, it is overwritten in horizontal alignment */
.Bubbles:hover {
  transform: scale(1.1);
}

/*-------- Tile CsSS --------*/
/* Parent Container of all the tiles */
.father {
  position: relative;
  width: 80%;
  overflow: hidden;
  transform: perspective(100px);
  transform-style: preserve-3d;
  left: 20%;
}

/* Container of complete tile (incl floating title) */
.contentContainer {
  position: absolute;
  width: 80%;
  transition: transform 1s ease-out;
}

/* Coloured Tile */
.content {
  padding: 40px;
  box-shadow: 0 0 5px var(--shadowStart), 8px 8px 5px var(--shadowEnd);
  border-radius: 8px;
  color: var(--text1);
}

/* Title above tiles*/
.tileTitles {
  color: var(--text);
  text-align: right;
  transition: width 0.4s ease-out;
  text-shadow: 0 0 5px var(--shadowStart), 4px 4px 5px var(--textShadingEnd);
  color: var(--text);
}

/* Next Back Buttons */
.nextBackButtons {
  display: none;
}
.nextBackButtonsContainer {
  right: 0;
  display: flex;
  /* justify-content: flex-end; */
  align-items: flex-end;
  height: 100%;
  width: 100%;
  flex-direction: column;
  font-size: 2em;
}
.svgContainers {
  padding: 2px 10px 2px 40px;
}
.displayNumber {
  padding: 0 11px;
}

@media only screen and (max-width: 768px) {
  .nextBackButtons {
    display: block;
    position: absolute;
    color: var(--text);
    right: 1vh;
    width: 100px;
    height: 100%;
    /* background-color: blanchedalmond; */
    z-index: 1;
  }

  .bubbleContainer {
    display: none;
  }

  .father {
    /* background-color: blueviolet; */
    width: 100%;
    left: 0;
  }
  .tileTitles {
    text-align: left !important;
  }
  .contentContainer {
    /* left:0; */
    position: static;
    width: 80%;
    -webkit-transform: none !important;
    transform: none !important;
    margin: 0 auto;
    text-align: left !important;
  }
}

.toggledBoxShadow {
  box-shadow: none !important;
}
