.light-theme .portfolioContainer {
  /* https://unsplash.com/photos/fHu41_uamys */
  background-image: url("https://images.unsplash.com/photo-1624399708998-94647fb54999?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80");
}
.dark-theme .portfolioContainer {
  /*https://unsplash.com/photos/wQLAGv4_OYs*/
  background-image: url("https://images.unsplash.com/photo-1541701494587-cb58502866ab?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80");
}

.popUpImage {
  visibility:visible;
  max-width: 250px;
  max-height: 200px;
  height: auto;
  opacity: 0;
  position: absolute;
  top: -200px;
  left: -50px;
  transform: rotate(-15deg);
  border-radius: 20px;
  z-index: -1;
  transition: opacity 0.8s;
  display: none;
}

.cardContent:hover .popUpImage {
  display: block;
  opacity: 1;
}
.js-tilt-glare{
  border-radius: inherit;
  z-index: -1;
}