.parent {
  /* perspective: 1px; */
  /* min-height: 100vh; */
  min-width: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  background-color: var(--dark);
}

.resumeParent {
  height: fit-content;
  margin-top: 10vh;
  padding-bottom: 5vh;
  /* background-image: url(https://images.unsplash.com/photo-1541701494587-cb58502866ab?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80); */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100px;
}

.parent::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.parent {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.radios {
  position: relative;
  width: 50%;
  margin: 20px auto;
  display: flex;
  justify-content: space-around;
}

.radioInput {
  position: absolute;
  right: 1000%;
  /* border: 3px solid blue; */
}

.label {
  float: left;
  width: 15%;
  padding-bottom: 15%;
  margin: 0 2.5%;
  background: rgba(255, 255, 255, .2);
  border-radius: 50%;
  cursor: pointer;
}

.slider {
  position: absolute;
  display: flex;
  left: 15%;
  top: 0;
  width: 10%;
  padding-bottom: 10%;
  margin: 2.5% 0 0 5%;
  background: #fff;
  transition: transform 1s;
  border-radius: 50%;
  animation-timing-function: ease-in-out;
  animation-duration: .3s;
  animation-fill-mode: forwards;
  transition: 0.2s left .05s ease-in-out;
  justify-content: center;
  align-items: flex-end;
}

.input1:checked~.slider {
  animation-name: input1;
  left: 15%;
}

.input2:checked~.slider {
  animation-name: input2;
  left: 65%;
}

@keyframes input1 {
  30%, 70% {
    transform: scale(0.5);
  }
}

@keyframes input2 {
  30%, 70% {
    transform: scale(0.5);
  }
}

a {
  color: var(--light);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.1s ease-in-out 0.1s;
}

a:hover {
  color: var(--lightest);
}

.pdfContainer {
  text-align: center;
  width: 60vw;
  position: relative;
  margin: 0 auto;
  height: auto;
  --rpv-core__inner-page-background-color: grey !important;
}

@media only screen and (max-width: 768px) {
  .resumeParent {
    border-radius: 0;
  }
}