.headerBar {
  position: fixed;
  top: 0;
  min-height: 10%;
  max-height: 10%;
  display: flex;
  width: 100%;
  right: 0;
  padding: 0px 20px;
  flex-flow: row nowrap;
  z-index: 10;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;

  gap: 1px;
}

.headerLinks {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
.optionsContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.extraContainer {
  margin: 5px;
  height: fit-content;
  background-color: var(--dark);
  border-radius: 40px;
}

.headerLink {
  color: var(--light);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.1s ease-in-out 0.1s;
}

.headerLink:hover {
  color: var(--lightest);
}
.headerScrollBar {
  display: none;
}

.colorUIChangeIcon {
  color: var(--text);
}

.iconContainer {
  position: relative;
  padding: 10px;
  flex-shrink: 0;
}

.iconContainer:hover > .colorUIChangeIcon {
  color: var(--lightest);
}

@media only screen and (max-width: 768px) {
  .headerLinks {
    gap: 0;
    max-width: 100%;
  }
  .headerLink {
    display: none;
  }
  .headerScrollBar {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 1;
  }
  .angleDoubleUp {
    display: none;
  }
  .popUpCircleContainer {
    display: inline;
  }
}

.dropDownContainer{
  position: relative;
  
  top: 0px;
  
}
.dropDownMenuContainer{
  position: absolute;
  top:1000%;
  left: 0;
  
}
.invisibility{
  display: none;
}
.modal {
  position: fixed;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow-y: scroll;
  padding: 20px;
  box-sizing: border-box;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* .modal_content {
  background-color: white;
  padding: 2px;
  overflow-x: hidden;
  border-radius: var(--postBorderRadius);
  border: 2px solid black;
} */
.modal_container {
  position: absolute;
  top: 5%;
  left: 25%;
  width: 50%;
  margin-bottom: 20px;
}
