.App {
  overflow: hidden;
  height: 100vh;
  scroll-behavior: smooth;
  caret-color: transparent;
}

.light-theme {
  --darkest: var(--darkest2);
  --dark: var(--dark2);
  --light: var(--light2);
  --lightest: var(--lightest2);
  --shadowStart: var(--shadowStart2);
  --shadowEnd: var(--shadowEnd2);
  --text: var(--text2);
  --invertedText: var(--text1);
  --filter: var(--filter2);
  --textShadingEnd: var(--textShadingEnd2);
  --backgroundShade: var(--backgroundShade2);
}

.dark-theme {
  --darkest: var(--darkest1);
  --dark: var(--dark1);
  --light: var(--light1);
  --lightest: var(--lightest1);
  --shadowStart: var(--shadowStart1);
  --shadowEnd: var(--shadowEnd1);
  --text: var(--text1);
  --invertedText: var(--text2);
  --filter: var(--filter1);
  --textShadingEnd: var(--textShadingEnd1);
  --backgroundShade: var(--backgroundShade1);
}
.dark-theme .hasBoxShadow {
  box-shadow: none !important;
  text-shadow: none !important;
}
.rpv-core__inner-page {
  --rpv-core__inner-page-background-color: none !important;
}

:root {
  // --darkest1: rgba(255, 255, 255, 0.1);
  // --dark1: #161623;
  // --light1: #00adb5;
  // --lightest1: #eeeeee;
  // --shadowStart1: rgba(96, 97, 99, 0.4);
  // --shadowEnd1: rgba(96, 97, 99, 0.7);
  // --textShadingEnd1: rgba(128, 128, 128, 0.4);

  --darkest1: rgb(18, 18, 18);
  --dark1: #2b2b3b;
  --light1: #35c8ec;
  --lightest1: #eeeeee;
  --shadowStart1: rgba(96, 97, 99, 0.4);
  --shadowEnd1: rgba(96, 97, 99, 0.7);
  --textShadingEnd1: rgba(128, 128, 128, 0.4);

  --darkest2: #f0f5f9;
  --dark2: #b0bbc4;
  --light2: #52616b;
  --lightest2: #1e2022;
  --shadowStart2: rgba(0, 0, 0, 0.05);
  --shadowEnd2: rgba(0, 0, 0, 0.1);
  --textShadingEnd2: rgba(128, 128, 128, 0.4); /*  */

  --lightPurpleBackground: #dccdf8;
  --lightBlueBackground: #c6d3fd;

  --text1: rgb(207, 207, 207);
  --text2: #161623;

  --filter1: invert(0%);
  --filter2: invert(100%);
  --popUpBorderRadius: 10px;

  --backgroundShade1: rgba(82, 85, 87, 0.8);
  --backgroundShade2: rgba(240, 245, 249, 0.8);

  --smallSize: 576px;
  --mediumsSize: 768px;
  --largeSize: 992px;
  --xlargeSize: 1200px;
}
