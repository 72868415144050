// @media only screen and (min-width: 992px) {
//   //Causes blur in cards to not work
//   // .light-theme .topLevelHome::before {
//   //   content: "";
//   //   position: absolute;
//   //   top: 0;
//   //   left: 0;
//   //   height: 100%;
//   //   width: 100%;
//   //   background-size: 200%;
//   //   // background-image: url("./splash.jpg");
//   //   background-position: center;
//   //   mix-blend-mode: screen;
//   // }
//   // .dark-theme .topLevelHome::before {
//   //   // background-image: url("./image.png");
//   //   filter: invert(1);
//   //   mix-blend-mode: darken;
//   // }
// }
$percent: 10%;
.dark-theme .topLevelHome {
  // background-image: linear-gradient(
  //   to right bottom,
  //   darken(#025b9f,$percent),
  //   darken(#534f9e,$percent),
  //   darken(#803c8e,$percent),
  //   darken(#9f2272,$percent),
  //   darken(#af004c,$percent),
  //   darken(#ba053b,$percent),
  //   darken(#c11b28,$percent),
  //   darken(#c3300c,$percent),
  //   darken(#d3470a,$percent),
  //   darken(#e35d07,$percent),
  //   darken(#f17203,$percent),
  //   darken(#ff8701,$percent)
  // );
  background-image: linear-gradient(
    to right bottom,
    #013e6d,
    #413e7c,
    #602d6a,
    #751954,
    #7c0036,
    #88042b,
    #94151f,
    #932409,
    #a23708,
    #b24905,
    #bf5a02,
    #cd6c00
  );
  background-size: cover;
  background-repeat: no-repeat;
}
.dark-theme .invert {
  filter: invert(1);
}

.light-theme .backgroundImage {
  // https://unsplash.com/photos/_3SFFsWqCVg
  background-image: url("https://images.unsplash.com/photo-1510936111840-65e151ad71bb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1067&q=80");
  min-height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // background-repeat: no-repeat;
}
.dark-theme .backgroundImage {
  // https://unsplash.com/photos/_3SFFsWqCVg
  // https://unsplash.com/photos/tk9RQCq5eQo
  background-image: url("https://images.unsplash.com/photo-1448067686092-1f4f2070baae?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80");
}

button,
[tyle="type-workplace"] {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: var(--text);
}
