.UnderConstruction {
  padding-top: 10vh;
  background-color: var(--dark);
  overflow-y: scroll;
}
.UnderConstruction::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.UnderConstruction {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.parent {
  background-image: url("https://images.unsplash.com/photo-1500989145603-8e7ef71d639e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1055&q=80");
  min-height: 80vh;
  max-height: 80vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 60%;
  height: 60%;
  background-color: var(--dark);
  color: var(--text);
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: var(--popUpBorderRadius);
}
.dividerAttachment {
  border-radius: 100px;
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .dividerAttachment {
    border-radius: 0;
  }
}
.dividerAttachmentTop {
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .dividerAttachmentTop {
    border-radius: 0;
  }
}
