/* http://css-workshop.com/hover-box-text-over-images-on-hover-and-more/ */

.photos {
    width: 100%;
    height: fit-content;
    overflow:auto; 
}

.hoverBox, .hoverBox * {
    box-sizing: border-box;
}

.hoverBox {
    position: relative;
    display: inline-block;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    margin: 10px;
}

.hoverBox:nth-child(even) {
    float: right;
}

.hoverBox img {
    max-width:100%;
    height:auto;
}

.hoverBox .hoverBoxBottom {
    display: block;
}

.hoverBox .hoverBoxTop {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 15px;
    -moz-transition: all 0.4s ease-in-out 0s;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -ms-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.hoverBox:hover .hoverBoxTop, .hoverBox.active .hoverBoxTop {
    opacity: 1;
}

.hoverBox .hoverBoxText {
    text-align: center;
    font-size: 18px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.hoverBox .hoverBoxText_mobile {
    font-size: 15px;
    border-top: 1px solid rgb(179, 179, 179);
    /* for old browsers */
    border-top: 1px solid rgba(179, 179, 179, 0.7);
    margin-top: 5px;
    padding-top: 2px;
    display: none;
}

.hoverBox.active .hoverBoxText_mobile {
    display: block;
}

.hoverBox:nth-child(even) .hoverBoxTop {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

.hoverBox:hover:nth-child(even) .hoverBoxTop, .hoverBox.active:nth-child(even) .hoverBoxTop{
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

.hoverBox:nth-child(odd) .hoverBoxTop {
	-moz-transform: translateX(100%);
	-webkit-transform: translateX(100%);
	-ms-transform: translateX(100%);
	transform: translateX(100%);
}
.hoverBox:hover:nth-child(odd) .hoverBoxTop,
.hoverBox.active:nth-child(odd) .hoverBoxTop {
	-moz-transform: translateX(0);
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
}