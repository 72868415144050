.home {
  position: relative;

  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--dark);
  /* background-size: cover;  */
}

$percent: 10%;
.top {
  position: relative;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-flow: column;
  // min-height: 60vh;
  // height: vh;
  // flex-basis: auto;
  /* background-image: url("https://cdn.pixabay.com/photo/2016/09/29/13/08/planet-1702788_960_720.jpg");
  background-size: cover; */
  background-image: linear-gradient(
    to right top,
    darken(#dccdf8, $percent),
    darken(#d8cef9, $percent),
    darken(#d3d0fb, $percent),
    darken(#cfd1fb, $percent),
    darken(#cbd2fc, $percent),
    darken(#c0d6ff, $percent),
    darken(#b5d9ff, $percent),
    darken(#abddff, $percent),
    darken(#95e5ff, $percent),
    darken(#7dedff, $percent),
    darken(#68f5fe, $percent),
    darken(#5ffbf1, $percent)
  );
  padding-top: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.personContainer {
  padding-top: 10vh;
  flex: 1 1 20%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.displayNone {
  display: none;
}
.top img {
  flex-grow: 1;
  flex-shrink: 1;
  border-radius: 50%;
  // padding: 10%;
  margin: 5%;
  max-width: 300px;
  width: 300px;
  height: 300px;
  // max-height: 100%;
}

.name {
  text-align: center;
  align-content: center;
  color: var(--darkest);
  text-shadow: 0 0 5px var(--shadowStart), 4px 4px 5px var(--textShadingEnd);
}

.top h1 {
  display: block;
  font-size: 60px;
  font-weight: bold;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  line-height: 83%;
  padding-bottom: 10px;
}

.selectedName {
  display: inline-block;
  text-align: center;
  // position: relative;
  font-size: 100px;
  line-height: 100px;
  // top:-10px;
  color: var(--text);
  transform: translateY(-100px);
}
.quoteContainer {
  min-height: 20vh;
  border-radius: var(--popUpBorderRadius);
  padding: 10px;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.divider {
  display: flex;
  min-height: 20%;
  max-height: 20%;
  background-color: var(--dark);
  color: var(--lightest);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5% 10%;
}
.dividerAttachment {
  border-radius: 100px;
  overflow: hidden;
}
.clearfix:before,
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  zoom: 1;
}

@media only screen and (max-width: 768px) {
  .divider {
    visibility: hidden;
    height: 20px;
  }
  .dividerAttachment {
    border-radius: 0;
  }
}
.dividerAttachmentTop {
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .dividerAttachmentTop {
    border-radius: 0;
  }
}

.skillsContainer {
  min-height: 80vh;
  // height: 80vh;
  width: 100%;
  background-color: transparent;
  padding: 30px;
  overflow: hidden;
}
.skills {
  min-height: 60%;
  width: 60%;
  left: 20%;
  top: 20%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--dark);
  text-align: center;
  color: var(--text);
  gap: 20px;
  overflow: auto;
  border-radius: var(--popUpBorderRadius);
}
.skillsLayout {
  display: flex;

  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  overflow-x: hidden;
  // gap: 20px;
}
@media only screen and (max-width: 768px) {
  .skills {
    width: 95%;
    left: 2.5%;
    top: 5%;
  }
  .skillsContainer{
    padding: 30px 0px;
  }
}
.circleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  flex: 1 1 33.33333333333333333333333%;
  padding: 20px;
}

.circle {
  position: relative;
  z-index: 0;
}

.circle::before {
  content: "";

  /* positioning / sizing */
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: var(--darkest);
  border-radius: 50%;
  border-color: #f1c40f #e74c3c #9b59b6 #2ecc71;
  border-style: solid;
  border-width: 3px;
  animation: animateCircle 5s linear infinite;
  z-index: -1;
}

.experienceImage {
  max-height: 65px;
  max-width: 65px;
  // object-fit: contain;
  z-index: 0;
  margin: 20px;
  background: transparent;
}

@keyframes animateCircle {
  from {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.workplaceContainer {
  height: 100%;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 100px;
  text-align: center;
}

.worplace {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: var(--text);
}
.workplaceImages {
  max-width: 100%;
  border-radius: 50%;
}

// IGNORE
// .iconContainer {
//   position: relative;
//   width: 20%;
//   height: 100%;
// }
// .iconContainer h4 {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   width: 100%;
// }
// .imageContainer {
//   display: inline;
//   max-width: 20%;
//   width: 20%;
// }
// .experienceIcon {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 60%;
// }
// @media only screen and (max-width: 768px) {
//   .imageContainer h4 {
//     display: none;
//   }
//   .iconContainer h4 {
//     display: none;
//   }
// }
