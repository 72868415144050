.headerBar {
    min-height: 10%;
    max-height: 10%;
    display: flex;
    /* height: 10vh; */
    /* position: fixed;
    top: 0;
    left: 0;
    right: 0;     */
    background-color: var(--darkest);
    /* padding: 0px 20px; */
    /* box-shadow: 10px 20px 200px #ccc; */
    flex-flow: row nowrap;
    /* flex-shrink: 0; */
    z-index: 10;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: 3px solid var(--dark);
}

.headerLinks {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10%;
    width: 100%;
    max-width: 60%;
}

.headerLink {
    color: var(--light);
    text-decoration: none;
    font-weight: bold;
    transition: color 0.1s ease-in-out 0.1s;
}

.headerLink:hover {
    color: var(--lightest);
}
.headerScrollBar{
    display: none;
}

@media only screen and (max-width: 768px) {
    .headerLinks{
        gap: 0;
        max-width: 100%;
    }
    .headerLink{
        display: none;
    }
    .headerScrollBar{
        display: inline;
        flex-shrink: 1;
        overflow: hidden;
    }

}

.bodyArea {
    /* min-width: 100%; */
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    height: 100%;
    display: flex;
    /* overflow-x: hidden;
    overflow-y: auto; */
    /* padding: 23px; */
    /* box-sizing: content-box; */
    flex-flow: column;
    /* perspective: 1px; */
}

.parent {
    /* perspective: 1px; */
    /* min-height: 100vh; */
    min-width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    background-color: var(--dark);
    /* background-color: var(--darkest); */
}

.parallaxParent {
    perspective: 1px;
    min-width: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    scroll-behavior: smooth;
}

.parent::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.parent {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.parallaxParent::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.parallaxParent {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.parallax {
    /* background-image: url('https://wallpapercave.com/wp/pEeUsp1.jpg'); */
    /* background-repeat: repeat; */
    background-color: var(--lightest);
    /* filter: var(--filter); */
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(-1px) scale(2);
    -moz-transform: unset;
    
    /* scroll-behavior: smooth; */
    background-color: var(--dark);
}


.footer {
    min-width: 100%;
    max-width: 100%;
    text-align: center;
    min-height: 10vh;
    max-height: 10vh;
    background-color: var(--darkest);
    /* padding: 50px 0px; */
    height: 10vh;
    line-height: 0%;
    /* border-top: 3px solid white; */
    transform: translateZ(0);
    color: var(--text);

    border-top: 3px solid var(--dark);
}

.colorUIChangeIcon{
    color: var(--light);
}

/* .colorUIChangeIcon:hover {
    color: var(--lightest);
} */

.iconContainer {
    padding: 10px;
    flex-shrink: 0;
}

.iconContainer:hover>.colorUIChangeIcon {
    color: var(--lightest);
}

.footerIcons {
    font-size: 3vh;
    margin: 2vh 5px 0 5px;
    text-decoration: none;
}