.contactParent {
    padding-top: 10vh;
    min-height: 80vh;
    height: fit-content;
    color: var(--text) ;
}

.contactParent hr {
    margin: 0;
    width: 100vw;
    line-height: 0%;
}

.contactParent h3 {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}

.icons {
    font-size: 8vh;
}

.contact a {
    color: var(--light);
    text-decoration: none;
    font-weight: bold;
    transition: color 0.1s ease-in-out 0.1s;
}

.contact a:hover {
    color: var(--lightest);
}