*, :after, :before {
  box-sizing: border-box;
}

a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}

.page {
  /* perspective: 1px; */
  min-height: 100vh;
  
  /* overflow-x: hidden;
    overflow-y: visible; */
}

.projectContainer {
  color: var(--text);
  text-align: center;
  box-sizing: border-box;
  
  /* background-image: url('https://wallpapercave.com/wp/pEeUsp1.jpg');
    background-repeat: repeat; */
  /* height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(0);
  /* transform-style: preserve-3d; */
  -moz-transform:unset;
  /* -webkit-transform: unset; */
}

/* .parallax {
    background-image: url('https://wallpapercave.com/wp/pEeUsp1.jpg');
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(-1px) scale(2);
} */

.projectContainer h1 {
  /* color: #2aa6cf; */
  color: var(--light1);
  padding: 5% 0;
}

/* .projectContainer p {
    padding-bottom: 5%;
} */

/* 
.projectContainer p {
    padding: 0 220px;
} */

.projects {
  display: flex;
  position: relative;
  
}

.timelineContainer {
  min-height: 70vh;
  max-height: 70vh;
  display: flex;
  flex-direction: column;
  /* position: relative; */
  margin: 40px 0px;
  margin-left: 40px;
  position: sticky;
  /* position: fixed; */
  top: 10vh;
  justify-content: space-between;
  /* border: 3px solid blueviolet; */
}

/* .timelineContainer::after {
    background-color: var(--light1);
    content: '';
    position: absolute;
    left: calc(5% - 2px);
    width: 4px;
    height: 100%;
} */

.timelineItem {
  display: flex;
  /* justify-content: flex-end; */
  /* padding-right: 30px; */
  /* position: relative; */
  /* margin: 10px 0; */
  /* width: 50%; */
  /* min-width: 500px; */
  align-self: flex-start;
  justify-content: flex-start;
  padding-left: calc(5% + 30px);
  padding-right: 0;
}

/* .timelineItem:nth-child(odd) {
    align-self: flex-end;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 0;
} */

.timelineContent {
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); */
  /* border-radius: 5px; */
  /* background-color: #fff; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: flex-end; */
  /* padding: 15px; */
  position: relative;
  width: 150px;
  /* max-width: 70%; */
  /* text-align: right; */
  /* text-align: left; */
  /* align-items: flex-start; */
}

.timelineBox {
  opacity: 0;
  filter: alpha(opacity=0);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: row-reverse;
  /* align-items: flex-end; */
  padding: 5px 10px;
  position: relative;
  width: max-content;
  max-width: 70%;
  /* text-align: right; */
  text-align: left;
  align-items: flex-start;
}

.timelineBox::after {
  content: " ";
  background-color: #fff;
  /* box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2); */
  position: absolute;
  /* right: -7.5px; */
  top: calc(50% - 7.5px);
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.timelineHalf {
  display: flex;
  /* max-width: 50%; */
  flex-wrap: wrap;
}

/* .timelineItem:nth-child(odd) .timelineContent {
    text-align: left;
    align-items: flex-start;
}

.timelineItem:nth-child(odd) .timelineContent::after {
    right: auto;
    left: -7.5px;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
} */

.timelineBox .timelineTag {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  top: 5px;
  /* left: 5px; */
  letter-spacing: 1px;
  padding: 2px;
  position: relative;
  text-transform: uppercase;
  left: auto;
  right: 5px;
  /* justify-content: flex-end;
    width: auto; */
}

/* .timelineItem:nth-child(odd) .timelineContent .timelineTag {
    left: auto;
    right: 5px;
} */

.timelineBox time {
  color: #777;
  font-size: 12px;
  font-weight: bold;
}

.timelineBox p {
  font-size: 16px;
  /* line-height: 12px; */
  margin: 15px 0;
  /* max-width: 250px; */
  padding: 0;
}

.timelineBox a {
  font-size: 14px;
  font-weight: bold;
}

.timelineBox a::after {
  content: " ►";
  font-size: 12px;
}

.timelineContent .timelineCircle {
  background-color: #00b3bd;
  /* border: 3px solid #2aa6cf; */
  border: 3px solid var(--light1);
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  /* right: -40px; */
  width: 20px;
  height: 20px;
  z-index: 100;
  right: auto;
  left: -40px;
}

/* .timelineBox {
    background-color: blue;
} */

/* .circleLink:hover>.timelineCircle {
    background-color: #b2deff;
} */

/* .timelineCircle:hover {
    background-color: #b2deff;
} */

.circleLink:hover~.timelineBox {
  opacity: 1;
  filter: alpha(opacity=1);
}

/* .timelineItem:nth-child(odd) .timelineContent .timelineCircle {
    right: auto;
    left: -40px;
} */

.projectList {
  /* margin-right: 220px;
  font-weight: bold;
  height: 100%; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  z-index: 1;
  padding-top: 10vh;
  
}

.tiltCard {
  position: relative;
  
  height: fit-content;
  margin: 30px;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: var(--popUpBorderRadius) !important;
  width: 1200px !important;
}

.project {
  text-align: left;
  /* padding-bottom: 30vh; */
  /* margin: 10vh 0 30vh 0; */
  /* top: 20vh; */
  /* bottom: 10vh; */
  width: 100%;
  height: 100%;
  /* position:sticky; */
  /* margin: 0 10%; */
  /* background-color: var(--backgroundShade); */
  /* border: 3px solid var(--lightest); */
  /* border-radius: var(--popUpBorderRadius); */
  padding: 20px;
  display: inline-block;
  color: var(--text);
}

.project h2 {
  /* color: #2aa6cf; */
  color: var(--light1);
}

.project h3 {
  /* font-weight: lighter; */
  font-size: larger;
}

.project p {
  padding: 0;
  /* font-weight: lighter; */
  font-weight: 400;
}

.project img {
  float: right;
  max-width: 30vw;
  max-height: 30vh;
  margin-left: 50px;
  /* border: 1px solid green; */
  object-fit: scale-down;
}

.project time {
  color: #777;
  font-size: initial;
  font-weight: normal;
}

.tags {
  display: inline;
  float: right;
  margin-left: 5px;
  padding: 1px 5px;
  border-radius: 5px;
  color: white;
  font-size: initial;
  font-weight: normal;
}

.linkData {
  margin-left: 10px;
  /* color: #2aa6cf; */
  color: var(--light1);
}

.progressContainer {
  background-color: var(--light1);
  position: absolute;
  left: calc(5% - 2px);
  width: 4px;
  height: 96%;
  top: 15px;
  /* bottom: 50px; */
  /* position: absolute;
    bottom: 0;
    z-index: 20;
    width: 100%;
    background-color: #f1f1f1;
    width: 100%;
    height: 8px;
    background: #ccc; */
}

.progressBar {
  height: 0%;
  /* background: #04AA6D; */
  background: #00dae6;
  width: 100%;
}

@media only screen and (max-width: 1023px) {
  .timelineContent {
    max-width: 100%;
  }
  .project img {
    float: unset;
    max-width: unset;
    width: 100%;
    height: 100%;
    margin-left: unset;
    margin: 10px 0;
    /* object-fit: cover; */
  }
  .tags {
    display: inline;
    float: none;
    margin-left: 5px;
    padding: 1px 5px;
    border-radius: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .timelineBox {
    display: none;
  }
  .timelineContent {
    width: 0px;
  }
  .timelineContainer {
    /* margin-left: 20px; */
    display: none;
  }
  .projectList {
    /* margin: 0 5%; */
    /* border: 3px solid greenyellow; */
    
  }
  .project img {
    float: none;
    width: 100%;
    height: 100%;
    /* border: 1px solid green; */
    object-fit:contain;
  }
  /* .timelineContent */
  /* .timelineItem:nth-child(odd) .timelineContent  */
  /* {
        padding: 15px 10px;
        text-align: center;
        align-items: center;
    } */
  /* .timelineContent .timelineTag {
        width: calc(100% - 10px);
        text-align: center;
    } */
  /* .timelineContent time {
        margin-top: 20px;
    } */
  /* .timelineContent a {
        text-decoration: underline;
    }
    
    .timelineContent a::after {
        display: none;
    } */
}